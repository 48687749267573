import { useReducer } from 'react'

import { ensureObject } from '@agnostack/lib-core'
import { DATALAYER, HUBSPOT_FIELDS } from '@agnostack/lib-utils-js'

const FIELD_MAPPINGS = Object.entries(HUBSPOT_FIELDS)

const conformFieldData = (fieldName, value) => {
  const [conformedFieldName] = FIELD_MAPPINGS.find(([, mappedFieldName]) => (
    fieldName === mappedFieldName
  )) ?? [fieldName]

  return {
    [conformedFieldName]: value,
  }
}

const conformInitialData = (initialData, email) => (
  Object.entries(ensureObject(initialData)).reduce((_initialData, [fieldName, value]) => ({
    ..._initialData,
    ...conformFieldData(fieldName, value),
  }), { ...email && { [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_EMAIL]: email } })
)

export const useHubspotData = (initialData, intialEmail) => {
  const [formData, updateData] = useReducer((_data, updates) => (
    Object.entries(ensureObject(updates)).reduce((__data, [fieldName, value]) => ({
      ...__data,
      ...conformFieldData(fieldName, value),
    }), _data)
  ), conformInitialData(initialData, intialEmail))

  return [formData, updateData]
}
