/* eslint-disable eqeqeq */
import { useState, useEffect, useReducer, useMemo } from 'react'
import { window } from 'browser-monads-ts'

import { ensureObject, objectNotEmpty, stringEmpty, stringNotEmpty } from '@agnostack/lib-core'

const GTM_EVENT = 'agnoStack.events.gtmEvent'

let defaultInitialized = false
export const useGTM = ({ id: gtmID, onEvent, onUpdate } = {}, container = window, dataLayerName = 'dataLayer') => {
  const [fallbackData, updateFallbackData] = useReducer((previousFallbackData, updatedFallbackData) => ({
    ...previousFallbackData,
    ...updatedFallbackData,
  }), {})

  const {
    [dataLayerName]: dataLayer,
    google_tag_manager: {
      [gtmID]: {
        [dataLayerName]: dataManager,
      } = {},
    } = {},
  } = ensureObject(container)

  const disableGTM = useMemo(() => (
    stringEmpty(gtmID) || (dataManager == undefined) || (dataLayer == undefined)
  ), [gtmID, (dataManager != undefined), (dataLayer != undefined)])

  const pushData = (updatedData) => {
    if (objectNotEmpty(updatedData)) {
      container.dispatchEvent?.(new CustomEvent(GTM_EVENT, { detail: updatedData }))
    }
  }

  const handleGTMDefault = (event) => {
    const {
      detail,
    } = event ?? {}

    if (detail) {
      if (disableGTM) {
        updateFallbackData(detail)
      }

      dataLayer.push(detail)
    }
  }

  const handleGTMExtended = (event) => {
    const {
      detail,
      detail: {
        event: eventName,
        ...data
      },
    } = event ?? {}

    if (detail) {
      // eslint-disable-next-line eqeqeq
      if (eventName && (onEvent != undefined)) {
        onEvent(eventName, data)
      }

      onUpdate?.(detail)
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    container.addEventListener?.(GTM_EVENT, handleGTMExtended)
    if (!defaultInitialized) {
      container.addEventListener?.(GTM_EVENT, handleGTMDefault)
      defaultInitialized = true
    }

    return () => {
      container.removeEventListener?.(GTM_EVENT, handleGTMExtended)
      container.removeEventListener?.(GTM_EVENT, handleGTMDefault)
    }
  }, [])

  if (disableGTM) {
    // eslint-disable-next-line eqeqeq
    console.info('Using Falling instead of datalayer')
    return { dataLayer: fallbackData, pushData, findData: (dataKey) => fallbackData[dataKey] }
  }

  return {
    dataLayer,
    pushData,
    findData: (dataKey) => {
      if (stringEmpty(dataKey)) {
        return undefined
      }

      try {
        return dataManager.get?.(dataKey) ?? fallbackData[dataKey]
      } catch (ignore) {
        console.warn('Ignoring error getting data from data manager.', ignore)
        return fallbackData[dataKey]
      }
    },
  }
}

export const useDataLayer = (gtmID, dataKey, container = window, dataLayerName = 'dataLayer') => {
  const [itemData, setItemData] = useState()

  const { pushData, findData } = useGTM({
    id: gtmID,
    onUpdate: (dataUpdate) => {
      if (dataUpdate?.[dataKey]) {
        setItemData(dataUpdate[dataKey])
      }
    },
  }, container, dataLayerName)

  useEffect(() => {
    setItemData(findData(dataKey))
  }, [dataKey])

  const pushItemData = (updatedItemData) => {
    if (stringNotEmpty(dataKey)) {
      pushData({ [dataKey]: updatedItemData })
    }
  }

  return [itemData, pushItemData]
}

export const useDataLayerEvent = (gtmID, eventKey, container = window, dataLayerName = 'dataLayer') => {
  const [eventData, setEventData] = useState()

  const { pushData } = useGTM({
    id: gtmID,
    onEvent: (eventName, eventDetail) => {
      if (eventName === eventKey) {
        setEventData(eventDetail)
      }
    },
  }, container, dataLayerName)

  useEffect(() => {
    let lastMatchingEvent
    try {
      if (container.dataLayer == undefined) {
        console.warn('Ignoring last matching event, this should not occur')
        // HMMMM: isnt this supposed to be container[dataLayerName].findLast???
      }
      lastMatchingEvent = container.dataLayer?.findLast?.(({ event } = {}) => (
        event === eventKey
      ))
    } catch (ignore) {
      console.warn('Ignoring error finding data from data layer.', ignore)
    }

    if (lastMatchingEvent) {
      setEventData(lastMatchingEvent)
    }
  }, [eventKey])

  const pushEventData = (dataUpdate) => pushData({
    event: eventKey,
    ...dataUpdate,
  })

  return [eventData, pushEventData]
}
