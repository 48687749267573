import React, { useMemo, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ensureDateTime, ensureObject, FREQUENCY_UNIT_EXTENDED } from '@agnostack/lib-core'
import { Close, Header } from '@zendeskgarden/react-modals'

import {
  useContextGlobal,
  GLOBAL_ACTIONS,
  GLOBAL_MODES,
  MODAL_TYPES,
} from '../../../util'
import { StyledModal, ModalBody } from './StatefulModal.lazy'

const StatefulModal = ({
  id: idProp,
  auto,
  title,
  frequency,
  until,
  open,
  url,
  children,
  type = MODAL_TYPES.DEFAULT,
  ...renderProps
} = {}) => {
  const [{ display, modalsState }, dispatch] = useContextGlobal()

  const id = useMemo(() => (
    idProp || uuidv4()
  ), [idProp])

  const {
    visible,
    currentTimestamp,
    previousTimestamp,
    title: modalTitle,
  } = useMemo(() => (
    ensureObject(modalsState?.[id])
  ), [modalsState, id])

  useEffect(() => {
    if (auto && !visible) {
      dispatch({
        type: GLOBAL_ACTIONS.DISPATCH_MODAL,
        id,
        visible: true,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto])

  const showModal = useMemo(() => {
    const frequencyCheck = (
      !frequency || (
        !previousTimestamp ||
        (ensureDateTime(previousTimestamp) < ensureDateTime(currentTimestamp).minus({ [FREQUENCY_UNIT_EXTENDED.SECONDS]: frequency }))
      )
    )
    const untilCheck = (
      !until ||
      (ensureDateTime(currentTimestamp) < until)
    )

    return visible && (children || url) && (open || [GLOBAL_MODES.DEBUG, GLOBAL_MODES.PREVIEW].includes(display) || (frequencyCheck && untilCheck))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentTimestamp, frequency, display, open, previousTimestamp, until, visible])

  const onModalClose = () => {
    dispatch({
      type: GLOBAL_ACTIONS.DISPATCH_MODAL,
      id,
      visible: false,
      title: null,
    })
  }

  if (!showModal) {
    return null
  }

  return (
    <StyledModal onClose={onModalClose}>
      <Header>
        {modalTitle || title}
      </Header>
      <Close aria-label="Close modal" onClick={onModalClose} />
      <ModalBody type={type} url={url} {...renderProps}>
        {children}
      </ModalBody>
    </StyledModal>
  )
}

export default StatefulModal
