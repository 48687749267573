import React, { useEffect, useMemo } from 'react'

import styled from 'styled-components'

import {
  ThemeProvider,
  DEFAULT_THEME as zendeskTheme,
} from '@zendeskgarden/react-theming'

import {
  hashCode,
  extendTheme,
} from '@agnostack/lib-utils-js'
import {
  useDelay,
  usePrevious,
  useMediaQuery,
  scrollTo,
} from '@agnostack/lib-utils-react'
import {
  isTrue,
  stringEmpty,
  stringNotEmpty,
  stringNotEmptyOnly,
  getDateTimeFromString,
  FREQUENCY_UNIT_EXTENDED,
  DATE_FORMAT_MED,
} from '@agnostack/lib-core'

import {
  getAnnouncementData,
  formatAnnouncement,
  useContextGlobal,
  GLOBAL_ACTIONS,
  GLOBAL_PARAMS,
  GLOBAL_MODES,
  TYPE_ANNOUNCEMENT,
} from '../util'
import { useQueryParam } from '../hooks'
import agnoStackTheme from '../theme'
import Navigation from './molecules/Navigation'
import StatefulModal from './molecules/StatefulModal'
import PageFragment from './molecules/PageFragment'

// NOTE: reload every 24 hours (in seconds)
const FREQUENCY = 24 * 60 * 60

const TRANSITION_DELAY = 500

const colors = {
  ...zendeskTheme.colors,
  ...agnoStackTheme.colors,
}

const theme = extendTheme({ ...zendeskTheme, ...agnoStackTheme, colors })

const mediumQuery = `(min-width: ${theme.breakpoints.md})`

const AnnouncementFragment = styled(PageFragment)`
  height: 90%;
`

const Layout = ({
  children,
  location,
  location: {
    hash,
    pathname,
  } = {},
  data: {
    allMdx: {
      edges = [],
    } = {},
  } = {},
}) => {
  const [{ display, bare }, dispatch] = useContextGlobal()

  const guid = useMemo(() => (
    `${hashCode(pathname)}-announcement`
  ), [pathname])
  const announcementData = getAnnouncementData(edges, display)
  const announcement = formatAnnouncement(announcementData)
  const displayParam = useQueryParam(GLOBAL_PARAMS.DISPLAY)
  const listingParam = useQueryParam(GLOBAL_PARAMS.LISTING)
  const bareParam = useQueryParam(GLOBAL_MODES.BARE)

  // TODO: consider if we don't want to shut off scroll for medium and below
  const [shouldScroll] = useMediaQuery(mediumQuery)
  const {
    hash: previousHash,
    pathname: previousPathname,
  } = usePrevious(location, {})

  const isBare = useMemo(() => (
    isTrue(bareParam || bare)
  ), [bareParam, bare])

  const hideModal = useMemo(() => (
    ['false', false].includes(announcement?.modal)
  ), [announcement?.modal])

  const { auto, frequency, until, date } = useMemo(() => {
    if (stringEmpty(announcement?.date) && stringEmpty(announcement?.until)) {
      return {
        auto: false,
      }
    }

    return {
      auto: true,
      frequency: announcement.frequency || FREQUENCY,
      date: announcement.date,
      until: stringEmpty(announcement.until)
        ? getDateTimeFromString({ value: announcement.date, format: DATE_FORMAT_MED })?.plus({ [FREQUENCY_UNIT_EXTENDED.DAYS]: 7 })
        : getDateTimeFromString({ value: announcement.until, format: DATE_FORMAT_MED }),
    }
  }, [announcement?.frequency, announcement?.date, announcement?.until])

  useDelay(TRANSITION_DELAY, () => {
    if (shouldScroll) {
      switch (true) {
        case stringNotEmpty(hash): {
          scrollTo(hash)
          break
        }

        case ((pathname !== previousPathname) || stringNotEmpty(previousHash)): {
          scrollTo('#scroll-root')
          break
        }

        default: {
          break
        }
      }
    }
  }, [shouldScroll, hash, pathname, previousHash, previousPathname])

  useEffect(() => {
    if (stringNotEmptyOnly(displayParam) && (display !== displayParam)) {
      dispatch({
        type: GLOBAL_ACTIONS.SET,
        payload: { [GLOBAL_PARAMS.DISPLAY]: displayParam },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayParam])

  useEffect(() => {
    if (stringNotEmptyOnly(bareParam) && (bare !== bareParam)) {
      dispatch({
        type: GLOBAL_ACTIONS.SET,
        payload: { [GLOBAL_MODES.BARE]: bareParam },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bareParam])

  useEffect(() => {
    dispatch({
      type: GLOBAL_ACTIONS.ACTIVE_LISTING,
      payload: listingParam,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingParam])

  return (
    <ThemeProvider theme={theme}>
      <Navigation bare={isBare}>
        <div
          id="scroll-root"
          data-aos="zoom-y-out"
          data-aos-delay="150"
          data-location={pathname}
          data-announcment-date={date}
          data-announcemnent-until={until}
          data-announcemnent-frequency={frequency}
        >
          {children}
          {(!isBare && !hideModal && (announcement?.type === TYPE_ANNOUNCEMENT)) && (
            <StatefulModal
              id={guid}
              title={announcement?.title}
              {...auto && {
                frequency,
                until,
                auto,
              }}
            >
              <AnnouncementFragment data={announcementData} />
            </StatefulModal>
          )}
        </div>
      </Navigation>
    </ThemeProvider>
  )
}

export default Layout
