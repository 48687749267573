import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'

import { toFormatted, toUnixInteger } from '@agnostack/lib-core'
import { DATALAYER } from '@agnostack/lib-utils-js'

import StatefulModal from '../StatefulModal'
import { ModalWrapper, ModalContainer } from '../../../styles/core'
import { ContactForm } from '../../atoms'
import { TITLE_LIVE_DEMO_REGISTER } from '../../../util'
import { StyledHeading } from './DemoModal.style'

const {
  HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
  HUBSPOT_MEETING_PROPERTIES,
  HUBSPOT_MEETING_PROPERTIES_TITLE,
  HUBSPOT_MEETING_PROPERTIES_START_TIME,
} = DATALAYER

const fields = [
  {
    label: 'First Name',
    value: HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  },
  {
    label: 'Last Name',
    value: HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
  }
]

const DemoModal = withTheme(({
  theme,
  id,
  children,
  title = 'agnoStack Demo Session',
  timestamp = toUnixInteger(),
  onSuccess,
}) => {
  const { data, formType, formattedTimestamp } = useMemo(() => {
    const _formattedTimestamp = toFormatted({ value: timestamp, format: 'cccc FFF' })
    const _formType = `${title} (${toFormatted({ value: timestamp, format: 'ccc FF ZZZZ' })})`
    const meetingTitle = `${title} (Week of ${toFormatted({ value: timestamp, format: 'MMM d, yyyy' })})`

    return {
      formattedTimestamp: _formattedTimestamp,
      formType: _formType,
      data: {
        [HUBSPOT_MEETING_PROPERTIES]: {
          [HUBSPOT_MEETING_PROPERTIES_TITLE]: meetingTitle,
          [HUBSPOT_MEETING_PROPERTIES_START_TIME]: timestamp,
        },
      },
    }
  }, [title, timestamp])

  return (
    <StatefulModal center id={id} background={theme.colors.tertiary}>
      <ModalWrapper>
        <ModalContainer>
          <StyledHeading tag="2">
            {`${TITLE_LIVE_DEMO_REGISTER}:`}
          </StyledHeading>
          <StyledHeading tag="5">
            {formattedTimestamp}
          </StyledHeading>
          {children}
          <ContactForm
            data={data}
            type={formType}
            fields={fields}
            onSuccess={onSuccess}
          />
        </ModalContainer>
      </ModalWrapper>
    </StatefulModal>
  )
})

export default React.memo(DemoModal)
