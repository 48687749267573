import { ensureObject } from '@agnostack/lib-core'

// TODO!!!: consolidate w/ lib-utils & lib-core
export const PREFIX_SEPARATOR = '--'
export const PREFIX_SEPARATOR_ALT = `~~`
export const PREFIX_SEPARATOR_SMALL = `~`
export const PREFIX_SHIPPING_ITEM = `shipping${PREFIX_SEPARATOR}`
export const PREFIX_DISCOUNT_ITEM = `discount${PREFIX_SEPARATOR}`

export const SHIPPING_CARRIER = {
  CUSTOM: 'custom',
  MANUAL: 'manual',
}

export const FREE_SHIPPING_ITEM = {
  rate_id: `${SHIPPING_CARRIER.MANUAL}-free`,
  carrier: SHIPPING_CARRIER.MANUAL,
  carrier_code: SHIPPING_CARRIER.MANUAL,
  service_code: 'free',
  service_type: 'Free shipping',
  shipping_cost: 0,
}

export const CACHE_TEMP_TTL = 20 * 1000 // 20 seconds
export const CACHE_MIN_TTL = 4 * 1000 // 4 seconds
export const CACHE_RAPID_TTL = 1 * 1000 // 1 seconds

export const CARTS_COUNT = 20

// TODO!!!!: replace these to just disbale flags in overrideFlags
export const PREVENT_CAPTURED_PAYMENTS = 'preventCapturedPayments' // TODO: rename to preventCapturePayments
export const PREVENT_STORED_INSTRUMENTS = 'preventStoredInstruments'

export const ORDER_REFUND_ACTION = {
  REFUND_BALANCE: 'refund_balance',
  REFUND_FULL: 'refund_full',
  REFUND: 'order_refund',
  CANCEL: 'order_cancel',
}

export const ORDER_ACTION = {
  ...ORDER_REFUND_ACTION,
  SHARE: 'order_share',
  RETURN: 'order_return',
  MARK_PAID: 'order_mark_paid',
  SEND_INVOICE: 'order_send_invoice',
  ADD_DISCOUNT: 'order_add_discount',
  REMOVE_DISCOUNT: 'order_remove_discount',
  REMOVE_DISCOUNTS: 'order_remove_discounts',
}

export const ORDER_ITEM_ACTION = {
  SHARE: 'order_item_share',
  EDIT: 'order_item_edit',
  DELETE: 'order_item_delete',
  REFUND: 'order_item_refund', // TODO: figure out how to re-enable w/ correct body for refunds
  ADD_DISCOUNT: 'order_item_add_discount',
  REMOVE_DISCOUNT: 'order_item_remove_discount',
}

// TODO: revert/remove/impliment this??
export const PAYMENT_ACTION = {
  MARK_REFUNDED: 'payment_mark_refunded',
}

export const INVOICE_ACTION = {
  SKIP: 'invoice_skip',
  UNSKIP: 'invoice_unskip',
  PROCESS: 'invoice_process',
  MODIFY_DATE: 'invoice_modify_date',
}

export const SUBSCRIPTION_ACTION = {
  NEXT_INVOICE_SKIP: INVOICE_ACTION.SKIP,
  NEXT_INVOICE_MODIFY_DATE: INVOICE_ACTION.MODIFY_DATE,
  MODIFY_FREQUENCY: 'subscription_modify_frequency',
}

export const ACTIONABLE_OPTION_TYPE = {
  SUBSCRIPTION_ITEM_ACTION: 'subscription_action',
  SUBSCRIPTION_ACTION: 'subscription_action',
  ORDER_ITEM_ACTION: 'order_item_action',
  INVOICE_ACTION: 'invoice_action',
  ORDER_ACTION: 'order_action',
  CART_ACTION: 'cart_action',
  ORDER_STATUS: 'order_status',
  EVENT_ACTION: 'event_action',
  TICKET_STATUS: 'ticket_status',
  SUBSCRIPTION_STATUS: 'subscription_status',
}

export const SUBSCRIPTION_STATUS_UPDATE = {
  DELETE: `${ACTIONABLE_OPTION_TYPE.SUBSCRIPTION_STATUS}.delete`,
  ACTIVATE: `${ACTIONABLE_OPTION_TYPE.SUBSCRIPTION_STATUS}.activate`,
  DEACTIVATE: `${ACTIONABLE_OPTION_TYPE.SUBSCRIPTION_STATUS}.deactivate`,
}

export const DATALAYER = {
  AGNOSTACK_PAGE_URI: 'ag.page.uri',
  AGNOSTACK_PAGE_NAME: 'ag.page.name',
  AGNOSTACK_CONTACT_TYPE: 'ag.contact.type',
  AGNOSTACK_INSTALLATION_ID: 'ag.installation.id',
  AGNOSTACK_INSTALLATION_LAST_ACCESS: 'ag.installation.last-access',
  AGNOSTACK_INSTALLATION_PLATFORMS_COMMERCE: 'ag.installation.platforms.commerce',
  AGNOSTACK_INSTALLATION_APPLICATION_ID: 'ag.installation.application-id',
  AGNOSTACK_INQUIRY_PLATFORMS_COMMERCE: 'ag.inquiry.platforms.commerce',
  AGNOSTACK_SUBSCRIPTION_ID: 'ag.subscription.id',
  AGNOSTACK_SUBSCRIPTION_PLAN: 'ag.subscription.plan',
  HUBSPOT_PORTAL_ID: 'hs.portal-id',
  HUBSPOT_USER_TOKEN: 'hs.user-token',
  HUBSPOT_MEETING: 'hs.meeting',
  HUBSPOT_MEETING_PROPERTIES: 'hs.meeting.properties',
  HUBSPOT_MEETING_PROPERTIES_ID: 'hs.meeting.properties.id',
  HUBSPOT_MEETING_PROPERTIES_TITLE: 'hs.meeting.properties.title',
  HUBSPOT_MEETING_PROPERTIES_BODY: 'hs.meeting.properties.body',
  HUBSPOT_MEETING_PROPERTIES_URL: 'hs.meeting.properties.url', // Calendar Event URL
  HUBSPOT_MEETING_PROPERTIES_NOTES: 'hs.meeting.properties.notes',
  HUBSPOT_MEETING_PROPERTIES_OUTCOME: 'hs.meeting.properties.outcome',
  HUBSPOT_MEETING_PROPERTIES_LOCATION: 'hs.meeting.properties.location', // Zoom URL
  HUBSPOT_MEETING_PROPERTIES_START_TIME: 'hs.meeting.properties.starttime',
  HUBSPOT_MEETING_PROPERTIES_DURATION: 'hs.meeting.properties.duration',
  HUBSPOT_MEETING_PROPERTIES_END_TIME: 'hs.meeting.properties.endtime',
  HUBSPOT_MEETING_PROPERTIES_OWNER_ID: 'hs.meeting.properties.owner.id',
  HUBSPOT_CONTACT: 'hs.contact',
  HUBSPOT_CONTACT_PROPERTIES: 'hs.contact.properties',
  HUBSPOT_CONTACT_PROPERTIES_ID: 'hs.contact.properties.id',
  HUBSPOT_CONTACT_PROPERTIES_EMAIL: 'hs.contact.properties.email',
  HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME: 'hs.contact.properties.firstname',
  HUBSPOT_CONTACT_PROPERTIES_LAST_NAME: 'hs.contact.properties.lastname',
  HUBSPOT_CONTACT_SOURCE: 'hs.contact.source',
  HUBSPOT_CONTACT_LIFECYCLE_STAGE: 'hs.contact.lifecycle-stage',
  HUBSPOT_FORM_ID: 'hs.form.id',
  HUBSPOT_FORM_FIELDS: 'hs.form.fields',
  // TODO: map HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME and HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME inside of HUBSPOT_FORM_FIELDS
  HUBSPOT_FORM_FIELDS_MESSAGE: 'hs.form.fields.message',
  SUPPORT_URL: 'support.url',
  SUPPORT_HOSTNAME: 'support.hostname',
  SUPPORT_PROFILE_ID: 'support.profile.id',
  ZENDESK_SUBDOMAIN: 'zd.subdomain',
  ZENDESK_SNIPPET_KEY: 'zd.snippet-key',
}

export const HUBSPOT_FIELDS = {
  [DATALAYER.AGNOSTACK_PAGE_URI]: 'pageUri',
  [DATALAYER.AGNOSTACK_PAGE_NAME]: 'pageName',
  [DATALAYER.AGNOSTACK_CONTACT_TYPE]: 'agnostack_contact_type',
  [DATALAYER.AGNOSTACK_INSTALLATION_ID]: 'agnostack_installation_id',
  [DATALAYER.AGNOSTACK_INSTALLATION_LAST_ACCESS]: 'agnostack_last_accessed',
  [DATALAYER.AGNOSTACK_INSTALLATION_PLATFORMS_COMMERCE]: 'agnostack_commerce_platform',
  [DATALAYER.AGNOSTACK_INSTALLATION_APPLICATION_ID]: 'agnostack_application_id',
  [DATALAYER.AGNOSTACK_SUBSCRIPTION_ID]: 'agnostack_subscription_id',
  [DATALAYER.AGNOSTACK_SUBSCRIPTION_PLAN]: 'agnostack_subscription_plan',
  [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_ID]: 'id',
  [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_EMAIL]: 'email',
  [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME]: 'firstname',
  [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_LAST_NAME]: 'lastname',
  [DATALAYER.HUBSPOT_CONTACT_SOURCE]: 'contact_source',
  [DATALAYER.HUBSPOT_CONTACT_LIFECYCLE_STAGE]: 'lifecyclestage',
  [DATALAYER.HUBSPOT_USER_TOKEN]: 'hutk',
  [DATALAYER.HUBSPOT_FORM_FIELDS_MESSAGE]: 'message',
  [DATALAYER.SUPPORT_URL]: 'support_url',
  [DATALAYER.SUPPORT_HOSTNAME]: 'support_hostname',
  [DATALAYER.SUPPORT_PROFILE_ID]: 'support_profile_id',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_BODY]: 'hs_meeting_body',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_TITLE]: 'hs_meeting_title',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_OUTCOME]: 'hs_meeting_outcome',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_NOTES]: 'hs_internal_meeting_notes',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_START_TIME]: 'hs_meeting_start_time',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_END_TIME]: 'hs_meeting_end_time',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_LOCATION]: 'hs_meeting_location',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_URL]: 'hs_meeting_external_url',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_OWNER_ID]: 'hubspot_owner_id',
}

// TODO: support other updates - ESPECIALLY change date/time (only then add export of method)?
const MEETING_FIELDS = {
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_TITLE]: 'subject',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_START_TIME]: 'timestamp',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_DURATION]: 'duration',
  [DATALAYER.HUBSPOT_MEETING_PROPERTIES_BODY]: 'body',
  [DATALAYER.HUBSPOT_CONTACT_PROPERTIES_EMAIL]: 'to',
}

export const conformMeetingFields = (data) => (
  Object.entries(ensureObject(data)).reduce((_conformedData, [_key, value]) => {
    const key = MEETING_FIELDS[_key] ?? _key

    return {
      ..._conformedData,
      [key]: value,
    }
  }, {})
)

export const ORDER_TYPE = {
  ORDER: 'order',
  SUBSCRIPTION: 'subscription_order',
}

// TODO: rename all of these TYPE constants to be TYPE vs TYPES
export const LINE_ITEM_TYPES = {
  CUSTOM_ITEM: 'custom_item',
  ORDER_ITEM: 'order_item',
  CART_ITEM: 'cart_item',
  DISCOUNT_ITEM: 'discount_item',
  SUBSCRIPTION_ITEM: 'subscription_item',
}

export const LINE_ITEM_ENTITY_TYPE = {
  DIGITAL: 'digital',
  PHYSICAL: 'physical',
  GIFT_CARD: 'gift_card',
  STORE_CREDIT: 'store_credit',
}

export const CART_LINE_ITEM_TYPES = [
  LINE_ITEM_TYPES.CART_ITEM,
  LINE_ITEM_TYPES.CUSTOM_ITEM,
  LINE_ITEM_TYPES.DISCOUNT_ITEM,
  LINE_ITEM_TYPES.SUBSCRIPTION_ITEM
]

export const ORDER_LINE_ITEM_TYPES = [
  LINE_ITEM_TYPES.ORDER_ITEM,
  LINE_ITEM_TYPES.CUSTOM_ITEM,
  LINE_ITEM_TYPES.DISCOUNT_ITEM,
  LINE_ITEM_TYPES.SUBSCRIPTION_ITEM
]

// HMMM: what about order_item?
export const PRODUCT_LINE_ITEM_TYPES = [
  LINE_ITEM_TYPES.CART_ITEM,
  LINE_ITEM_TYPES.SUBSCRIPTION_ITEM
]

export const PURCHASEABLE_LINE_ITEM_TYPES = [
  ...PRODUCT_LINE_ITEM_TYPES,
  LINE_ITEM_TYPES.ORDER_ITEM
]

// TODO: Add Product/Product Group to DataItems component
// TODO consolidate DATA_ITEM_TYPES/getDataType (ex BC transformOrderDisplayType)
export const DATA_ITEM_TYPES = {
  TEXT: 'data_text',
  TAGS: 'data_tags',
  JSON: 'data_json',
  LINK: 'data_link',
  PILL: 'data_pill',
  FACT: 'data_fact',
  BADGE: 'data_badge',
  GAUGE: 'data_gauge',
  PHONE: 'data_phone',
  PRICE: 'data_price',
  SCALE: 'data_scale',
  BUTTON: 'data_button',
  TOGGLE: 'data_toggle',
  GROUPS: 'data_groups',
  NESTED: 'data_nested',
  RATING: 'data_rating',
  RETURN: 'data_return',
  CREDITS: 'data_credits',
  RELATED: 'data_related',
  PERCENT: 'data_percent',
  BOOLEAN: 'data_boolean',
  DISCOUNT: 'data_discount',
  PRODUCTS: 'data_products',
  DROPDOWN: 'data_dropdown',
  DATESTAMP: 'data_datestamp',
  TIMESTAMP: 'data_timestamp',
  TEXTBLOCK: 'data_textblock',
  IDENTIFIER: 'data_identifier',
  SUBSCRIPTION: 'data_subscription',
  // TODO: add data_frequency (render FrequencyBadge w/ or w/o edit modal, change provider.meta.frequency to be a duration object???)
}

export const PRODUCT_TYPES = {
  CONFIGURABLE: 'product_configurable',
  BUNDLED: 'product_bundled',
  GROUPED: 'product_grouped',
  SIMPLE: 'product_simple',
}

export const PRODUCT_STATUS_TYPES = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  PRE_ORDER: 'preorder',
  DRAFT: 'draft',
}

export const PRODUCT_TYPES_EXTENDED = {
  CHILD: 'product_child',
}

export const PRODUCT_DATA = {
  INVENTORY: 'inventory',
  FEEDBACK: 'feedback',
  VENDOR: 'vendor',
}

export const PRODUCT_EXCERPT_LENGTH = 1000

// NOTE these are added directly on modifier values
export const MODIFIER_VALUE = 'modifier_value'
// NOTE these are added directly on a variation
export const MODIFIER_VARIATION = 'modifier_variation'

export const MODIFIER_TYPES = {
  // NOTE these are added directly on a modifier which DOES NOT affect the variation selection
  MODIFIER_OPTION: 'modifier_option',
  MODIFIER_OPTION_GROUP: 'modifier_option_group',
  // NOTE these are added directly on a modifier which DOES affect the variation selection
  MODIFIER_PRODUCT: 'modifier_product',
  // NOTE this is usually a fictitious modified added if a child product allows quantity
  MODIFIER_QUANTITY: 'modifier_quantity',
}

// TODO consolidate DATA_ITEM_TYPES/getDataType (ex BC transformOrderDisplayType)
export const DISPLAY_TYPES = {
  DATE: 'display_date',
  SELECT: 'display_select',
  RADIO: 'display_radio',
  INPUT: 'display_input',
  CHECKBOX: 'display_checkbox',
  TEXTAREA: 'display_textarea',
  NUMERIC: 'display_numeric',
  STATIC: 'display_static',
}

// TODO consolidate DATA_ITEM_TYPES/getDataType (ex BC transformOrderDisplayType)
export const DISPLAY_TYPES_TO_DATA_ITEM = {
  [DISPLAY_TYPES.DATE]: DATA_ITEM_TYPES.DATESTAMP,
  [DISPLAY_TYPES.SELECT]: DATA_ITEM_TYPES.TEXT,
  [DISPLAY_TYPES.RADIO]: DATA_ITEM_TYPES.TEXT,
  [DISPLAY_TYPES.INPUT]: DATA_ITEM_TYPES.TEXT,
  [DISPLAY_TYPES.CHECKBOX]: DATA_ITEM_TYPES.BOOLEAN,
  [DISPLAY_TYPES.TEXTAREA]: DATA_ITEM_TYPES.TEXT,
  [DISPLAY_TYPES.NUMERIC]: DATA_ITEM_TYPES.PILL,
}

// NOTE: These are used by ProductEditConfigurable to change data structure
export const DISPLAY_TYPES_TEXT = [
  DISPLAY_TYPES.NUMERIC,
  DISPLAY_TYPES.INPUT,
  DISPLAY_TYPES.TEXTAREA,
  DISPLAY_TYPES.DATE
]

export const STEP = {
  DISABLED: 'disabled', // NOTE: this is currently a step status
  SELECTED: 'selected',
}

export const STEP_STATUS = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
  FAILED: 'failed',
}

export const OAUTH_CLIENT_PREFIX = 'agnostack-client-'

export const PLAN_TYPES = {
  METERED: 'metered',
  LICENSED: 'licensed',
}

export const TIMELINE_EVENT_PROPERTIES = {
  EVENT_TYPE: 'event_type',
  EVENT_TYPE_LEGACY: 'Event Type',
}

export const TIMELINE_EVENT_TYPES = {
  CUSTOMER: 'event_customer',
  TICKET: 'event_ticket',
  ORDER: 'event_order',
  BASIC: 'event_basic',
  ADVANCED: 'event_agnostack',
}

export const TIMELINE_EVENT_ADVANCED_TYPES = {
  ORDER_CANCELLED: 'order_cancelled',
  ORDER_CREATED: 'order_created',
  ORDER_EDITED: 'order_edited',
  ORDER_UPDATED: 'order_updated',
  ORDER_REFUNDED: 'order_refunded',
  INVOICE_REFUNDED: 'invoice_refunded',
  REVIEW_UPDATED: 'review_updated',
  REVIEW_ACTION: 'review_action',
  CART_COLLAB: 'cart_collab',
  TAGS_SYNC: 'tags_sync',
}

// NOTE: these values are important as they are used for translation key
export const SYNC_MODE_TYPE = {
  TO_SOURCE: 'source',
  TO_TARGET: 'target',
}

export const ADDRESS_TYPES = {
  SHIPPING: 'shipping-address',
  BILLING: 'billing-address',
}
