import React, { useState, useEffect } from 'react'

import { Inline } from '@zendeskgarden/react-loaders'
import { Input, Message } from '@zendeskgarden/react-forms'
// TODO: explore importing Dropdown/Menu from @agnostack/components-atoms
import { Dropdown, Menu, Field } from '@zendeskgarden/react-dropdowns'

import {
  stringNotEmpty,
  objectNotEmpty,
  ensureObject,
  ensureArray,
  arrayNotEmpty,
} from '@agnostack/lib-core'
import {
  validateEmail,
  DATALAYER,
  COMMERCE_PLATFORMS,
} from '@agnostack/lib-utils-js'
import { useVerification } from '@agnostack/verifyd/react'
import { useDataLayer, useHubspotData } from '@agnostack/lib-utils-react'

import {
  FormWrapper,
  FormContainer,
  FieldsWrapper,
  FieldWrapper,
  MessageWrapper,
  DropdownWrapper,
  SubmitButton,
  StyledItem,
  StyledSelect,
} from './ContactForm.style'

const {
  AGNOSTACK_INQUIRY_PLATFORMS_COMMERCE,
  AGNOSTACK_INSTALLATION_PLATFORMS_COMMERCE,
  HUBSPOT_CONTACT_PROPERTIES_EMAIL,
  HUBSPOT_CONTACT,
  HUBSPOT_FORM_FIELDS,
  HUBSPOT_FORM_FIELDS_MESSAGE,
  HUBSPOT_MEETING_PROPERTIES,
  HUBSPOT_USER_TOKEN,
  SUPPORT_PROFILE_ID,
} = DATALAYER

const PLATFORMS = [
  { label: 'Choose Commerce Platform', value: '' },
  ...Object.values(COMMERCE_PLATFORMS).map((platform) => ({
    label: platform,
    value: platform,
  })),
  { label: 'Other', value: 'Other' }
]

// eslint-disable-next-line prefer-destructuring
const GATSBY_API_MARKETING = process.env.GATSBY_API_MARKETING // NOTE: combined these fails at runtime in gatsby
// eslint-disable-next-line prefer-destructuring
const GATSBY_GTM_ID = process.env.GATSBY_GTM_ID // NOTE: combined these fails at runtime in gatsby

const Selected = ({ label, value }) => (
  <StyledSelect value={value}>{label}</StyledSelect>
)

const ContactForm = ({
  type = 'agnoStack WebSite Inquiry',
  data: initialData,
  fields,
  onSuccess = () => {},
  ...renderProps
}) => {
  const {
    processResponse,
    prepareVerification,
  } = useVerification({ publicKey: process.env.GATSBY_VERIFICATION_PUBLIC_KEY, disableRecryption: process.env.GATSBY_DISABLE_RECRYPTION })

  const [customerEmail, setCustomerEmail] = useDataLayer(GATSBY_GTM_ID, HUBSPOT_CONTACT_PROPERTIES_EMAIL)
  const [hubSpotContact, setHubSpotContact] = useDataLayer(GATSBY_GTM_ID, HUBSPOT_CONTACT)
  const [inquiryPlatform, setInquiryPlatform] = useDataLayer(GATSBY_GTM_ID, AGNOSTACK_INQUIRY_PLATFORMS_COMMERCE)
  const [installationPlatform] = useDataLayer(GATSBY_GTM_ID, AGNOSTACK_INSTALLATION_PLATFORMS_COMMERCE)
  const [hubSpotToken] = useDataLayer(GATSBY_GTM_ID, HUBSPOT_USER_TOKEN)
  const [supportProfileId] = useDataLayer(GATSBY_GTM_ID, SUPPORT_PROFILE_ID)
  const [selectedPlatform, setSelectPlatform] = useState(inquiryPlatform || installationPlatform || PLATFORMS[0])
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState('')

  const [{
    [HUBSPOT_CONTACT_PROPERTIES_EMAIL]: email,
    [HUBSPOT_MEETING_PROPERTIES]: meetingData,
    ...formData
  }, updateData] = useHubspotData(initialData, customerEmail)

  const { isValid, validationStatus: emailValidation } = validateEmail(email)

  useEffect(() => {
    const { label } = ensureObject(selectedPlatform)
    setInquiryPlatform(label)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlatform])

  const handleSubmit = async () => {
    if (stringNotEmpty(email)) {
      setResponse('')
      setLoading(true)

      try {
        setCustomerEmail(email)

        const payload = {
          [HUBSPOT_CONTACT_PROPERTIES_EMAIL]: email,
          [HUBSPOT_FORM_FIELDS]: Object.entries({
            [HUBSPOT_FORM_FIELDS_MESSAGE]: [type, inquiryPlatform].filter(stringNotEmpty).join(' - '),
            ...formData,
          }).reduce((_fieldsData, [name, value]) => ([
            ..._fieldsData,
            { name, value }
          ]), []),
          ...objectNotEmpty(meetingData) && { [HUBSPOT_MEETING_PROPERTIES]: meetingData },
          ...stringNotEmpty(hubSpotToken) && { [HUBSPOT_USER_TOKEN]: hubSpotToken },
          ...objectNotEmpty(hubSpotContact) && { [HUBSPOT_CONTACT]: hubSpotContact },
          ...stringNotEmpty(supportProfileId) && { [SUPPORT_PROFILE_ID]: supportProfileId },
        }

        const [requestPath, requestOptions, derivedSecretKey] = await prepareVerification(`${GATSBY_API_MARKETING}/send`, {
          method: 'POST',
          mode: 'cors',
          body: payload,
        }) ?? []

        const apiResponse = await fetch(requestPath, requestOptions)
          .then((res) => res.json())
          .then(async (encryptedResponse) => (
            processResponse(encryptedResponse, derivedSecretKey)
          ))
          .catch((warn) => {
            console.warn('Ignoring warning submitting form', warn)
          })

        const {
          message: responseMessage,
          contact: responseContact,
        } = ensureObject(apiResponse)

        if (objectNotEmpty(responseContact)) {
          setHubSpotContact(responseContact)
        }

        setResponse(responseMessage)
        onSuccess()
      } catch (err) {
        console.log('An error occurred submitting form', err)
        if (err.response && err.response.data) {
          setResponse(err.response.data.message)
        } else {
          setResponse(
            'An error occurred, please contact us at support@agnostack.com.'
          )
        }
      }
      setLoading(false)
    } else {
      setResponse('You must provide an email address.')
    }
  }

  const handleKeyDown = ({ key } = {}) => {
    switch (key) {
      case 'Enter':
        handleSubmit()
        break
      default:
        break
    }
  }

  return (
    <FormWrapper {...renderProps}>
      {arrayNotEmpty(fields) ? (
        <>
          <FieldsWrapper>
            <FieldWrapper>
              <Input
                placeholder="Email"
                value={email}
                validation={emailValidation}
                onChange={({ target: { value: updatedValue } }) => updateData({ [HUBSPOT_CONTACT_PROPERTIES_EMAIL]: updatedValue })}
                onKeyDown={handleKeyDown}
              />
            </FieldWrapper>
            {fields.map(({ label, value: fieldName }) => (
              <FieldWrapper key={`field-${fieldName}`}>
                <Input
                  placeholder={label}
                  value={formData?.[fieldName]}
                  onChange={({ target: { value: updatedValue } }) => updateData({ [fieldName]: updatedValue })}
                  onKeyDown={handleKeyDown}
                />
              </FieldWrapper>
            ))}
          </FieldsWrapper>
          <SubmitButton
            onClick={handleSubmit}
            disabled={!isValid}
          >
            {loading ? <Inline /> : 'Submit'}
          </SubmitButton>
        </>
      ) : (
        <FormContainer>
          <div>
            <FieldWrapper>
              <Input
                placeholder="Enter your email"
                value={email}
                validation={emailValidation}
                onChange={({ target: { value: updatedValue } }) => updateData({ [HUBSPOT_CONTACT_PROPERTIES_EMAIL]: updatedValue })}
                onKeyDown={handleKeyDown}
              />
            </FieldWrapper>
            <DropdownWrapper>
              <Dropdown
                onSelect={setSelectPlatform}
                selectedItem={selectedPlatform}
                downshiftProps={{ itemToString: (item) => item && item.label }}
              >
                <Field>
                  <Selected {...selectedPlatform} />
                </Field>
                <Menu>
                  {ensureArray(PLATFORMS).map((platform) => {
                    const { label, value } = ensureObject(platform)
                    return (
                      <StyledItem value={platform} key={`platform-${value}`}>{label}</StyledItem>
                    )
                  })}
                </Menu>
              </Dropdown>
            </DropdownWrapper>
          </div>
          <SubmitButton
            onClick={handleSubmit}
            disabled={!isValid}
          >
            {loading ? <Inline /> : 'Submit'}
          </SubmitButton>
        </FormContainer>
      )}
      <MessageWrapper>
        <Message>{response}</Message>
      </MessageWrapper>
    </FormWrapper>
  )
}

export default ContactForm
